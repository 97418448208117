import React from "react";

import "./Logomaker.scss";

const LogoMaker = () => {
  return (
    <div className="Logomaker">
      <div className="left">
        <h1 className="logo-title">AN EXPERIENCE MAGIC</h1>

        <p>
          with EDXU's low latency tech feels like you 're in the same institue
          collaboration so easy it never feel like you're remote{" "}
        </p>

        <p>Pre-Designed by top-talent. Just add your touch.</p>

        <button className="fiverr-logo-maker">Explore More </button>

        <button className="fiverr-logo-maker">Join for free</button>
      </div>
    </div>
  );
};

export default LogoMaker;
