import React from "react";

import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";

import Header from "./components/header/Header";

import Footer from "./components/footer/Footer";

import Home from "./components/Home/Home";

import Login from "./components/login/Login";

import About from "./components/about/About";

import Contact from "./components/contact/Contact";

function App() {
  const Layout = () => {
    return (
      <div className="app">
        <Header />
        <Outlet />
        <Footer />
      </div>
    );
  };
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/about",
          element: <About />,
        },
        {
          path: "/contact",
          element: <Contact />,
        },
      ],
    },

    {
      path: "/login",
      element: <Login />,
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
