import React from "react";

import "./Home.scss";

import Card from "../card/Card";
import { cards } from "../../data";
import LogoMaker from "../logomaker/LogoMaker";
import Footer from "../footer/Footer";

const Home = () => {
  return (
    <>
      <div className="home">
        <div className="container">
          <h1 className="home_title">
            A Place for collaborate, grow and educate.
            <br />
          </h1>

          <p>
            imagine a platform where student from any institute can join and
            <br />
            collaborate , participate or simply can just chill together
          </p>

          <button className="btn_free">Join for free</button>
        </div>
      </div>

      <div className="features_card">
        <h1 className="featured_title">
          Break The tiers <br />
          <span className="white"> Build the communities</span>
        </h1>
        <div className="child_card">
          {cards.map((card) => (
            <Card key={card.id} item={card} />
          ))}
        </div>
      </div>

      <div className="grid_two">
        <div className="left">
          <div className="content">
            <h1 className="grid_title">Chat , Collaborate and Work Together</h1>
            <p>
              Not from IIT's or IIM's , No worries EDXU provides students from
              any tier collages to join other institutes , communities and
              provide a platform where you can collaborate engagingly and
              effectively
            </p>
          </div>
          <div className="img">
            <img src="./asset/collaborate.jpg" alt="" />
          </div>
        </div>

        <div className="right">
          <div className="img">
            <img
              src="https://plus.unsplash.com/premium_photo-1661964271680-4cea183bc277?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTl8fGZhbWV8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60"
              alt=""
            />
          </div>
          <div className="content">
            <h1 className="grid_title">
              Find People With Same Interest As You
            </h1>
            <p>
              EDXU is not just edtech platform , it is your personal place where
              you can meet and connect with other great mind like yours
            </p>
          </div>
        </div>
      </div>

      <LogoMaker />

      {/* <Footer /> */}
    </>
  );
};

export default Home;
