import React from "react";

import { Link } from "react-router-dom";

import "./Card.scss";

const Card = ({ item }) => {
  return (
    <Link to="/category?id=123">
      <div className="card">
        <img src={item.img} alt="" />

        <div className="desc_main">
          <h1>{item.title}</h1>
          <p>{item.desc}</p>
        </div>
      </div>
    </Link>
  );
};

export default Card;
