import React from "react";

import { useEffect, useState } from "react";

import { Link, useLocation } from "react-router-dom";

import "./Header.scss";

const Header = () => {
  const [active, setActive] = useState(false);

  const { pathname } = useLocation();

  const scrollActive = () => {
    window.scrollY > 0 ? setActive(true) : setActive(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollActive);
    return () => {
      window.removeEventListener("scroll", scrollActive);
    };
  }, []);

  return (
    <div className={active ? "active header" : "header"}>
      <div className="container">
        <div className="left">
          <Link to="/" className="links_to">
            Home
          </Link>

          <Link to="/about" className="links_to">
            About Us
          </Link>

          <Link to="/contact" className="links_to">
            Contact
          </Link>
        </div>

        <div className="logo">
          <img src="./asset/edxu_logo.svg" alt="" />
        </div>

        <div className="right">
          <Link to="/login" className="links_to button_primary">
            Login
          </Link>

          <Link to="/" className="links_to button_secondary">
            Join For Free
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Header;
