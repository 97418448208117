export const cards = [
  {
    id: 1,
    title: "Apply Online",
    desc: "Easy apply online through our website",
    img: "https://images.pexels.com/photos/7532110/pexels-photo-7532110.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load",
  },
  {
    id: 2,
    title: "Download Prospectus",
    desc: "Go through aour prospectus",
    img: "https://images.pexels.com/photos/11295165/pexels-photo-11295165.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load",
  },
  {
    id: 3,
    title: "Collaborate",
    desc: "collaborate with other collage students and explore more",
    img: "https://images.pexels.com/photos/4371669/pexels-photo-4371669.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load",
  },
];
