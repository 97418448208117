import React from "react";

import "./Footer.scss";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="left">
          <img src="./asset/edxu_logo.svg" alt="" />
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis
            quis ipsum sed in, voluptatem ea sunt illo iste excepturi,
            cupiditate fugit enim obcaecati! Nisi ut sint rerum architecto
            quidem fugiat.
          </p>
        </div>
        <div className="middle">
          <p>Networks</p>
          <p>Collaborations</p>
          <p>Terms & Conditions</p>
          <p>Portfolio</p>
          <p>Intitutions</p>
        </div>
        <div className="middle">
          <p>Networks</p>
          <p>Collaborations</p>
          <p>Terms & Conditions</p>
          <p>Portfolio</p>
          <p>Intitutions</p>
        </div>
        <div className="right">
          <p>edxuCore@gmail.com</p>
          <p>call : 090909900</p>

          <p>Nearest Location to you</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
